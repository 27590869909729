import React, { useState, useRef } from "react";
import NavHeader from "../navigation/NavHeader";
import AsideLeft from "../aside/AsideLeft";
import AsideRight from "../aside/AsideRight";
import Footer from "../navigation/Footer";
import GoUpDown from "../navigation/GoUpDown";
import Joyride, { ACTIONS, EVENTS, ORIGIN, STATUS, CallBackProps } from 'react-joyride';


const finishedTourConfig = localStorage.getItem("finished-tour");
const finishedTour = finishedTourConfig === "true";



const productTourConfig = {
    steps: [
        {
            target: '#menu-link-log',
            content: (
                <div className="flex flex-col w-full items-start text-left">
                    <div ><b>Welcome to Wefayo!</b></div>
                    <div >Wefayo connects people with medical conditions, health issues, or special needs—along with their friends and families—to a supportive community of caring individuals, service and healthcare providers, employers, and resources. </div>
                </div>
            ),
            disableBeacon: true,
        },
        {
            target: '#menu-link-communities',
            content: 'Explore and connect with different communities, make new friends, get inspired by success stories, share your knowledge, and access exclusive content.',
            disableBeacon: true,
        },
        {
            target: '#menu-link-jobs',
            content: "Discover exciting career opportunities or post job listings for others to see.",
            disableBeacon: true,
        },
        {
            target: '#menu-link-events',
            content: 'Join fun events, including social activities, training sessions, and new programs. Some are online, while others take place onsite.',
            disableBeacon: true,
        },
        // {
        //     target: '#menu-link-chat',
        //     content: 'Join fun events, including social activities, training sessions, and new programs. Some are online, while others take place onsite.',
        //     disableBeacon: true,
        // },
        {
            target: '#menu-btn-invite',
            content: 'Invite your friends to join you! You can send invitations individually or copy the link to share on social media with many friends at once.',
            disableBeacon: true,
        },
        {
            target: '#menu-general-invite',
            content: 'Easily find members, communities, jobs, volunteer opportunities, events, or any keyword across the platform.',
            disableBeacon: true,
        },
        {
            target: '#search-community-1-form',
            content: 'Use this feature to search for specific community names.',
            disableBeacon: true,
        },
        {
            target: '#menu-invitation-leaders',
            content: 'Check out the top inviters! To make this list, send out lots of invitations and have them accepted by your friends.',
            disableBeacon: true,
        },
        {
            target: '#menu-activities-leaders',
            content: 'Meet the members with the most activity! To get on this list, engage by making posts, commenting, and liking content.',
            disableBeacon: true,
        }
        // ,
        // {
        //     target: '#menu-support',
        //     content: 'To add communities or get help, click here.',
        //     disableBeacon: true,
        // },
    ]
};



const MainLayout = (props) => {
    const { currentPage } = props;
    const mobileChatClasses = props?.mobileChatClasses ?? "";
    const showAsideLeft = props?.showAsideLeft ?? true;
    let ads = [];
    if (props.ads) {
        ads = [...props.ads];
    }

    const { steps } = productTourConfig;
    const [startTour, setStartTour] = useState(false);
    const [stepIndex, setStepIndex] = useState(0);
    const tourRenderRef = useRef(1);



    const handleStartTour = () => {
        setStartTour(true);
        tourRenderRef.current = tourRenderRef.current + 1;
    }

    const handleJoyrideCallback = (data) => {
        const { action, index, status, type } = data;

        if (action === ACTIONS.CLOSE) {
            // do something
            setStepIndex(0);
            setStartTour(false);
        }

        if (STATUS.SKIPPED == status) {
            const nextIndex = index + 2;
            if (nextIndex > steps.length - 1) {
                setStepIndex(0);
                setStartTour(false);
            } else {
                setStepIndex(nextIndex);
                setStartTour(false);
                setTimeout(() => {
                    setStartTour(true);
                }, 150);
            }
        } else if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
            // Update state to advance the tour
            setStepIndex(index + (action === ACTIONS.PREV ? -1 : 1));
        } else if ([STATUS.FINISHED].includes(status)) {
            // You need to set our running state to false, so we can restart if we click start again.
            setStartTour(false);
        }
    }

    // className={`${currentPage === 'chat' ? 'fixed overflow-y-scroll' : 'flex min-h-full overflow-y-auto flex-col'}`}
    // style={{ "-webkit-overflow-scrolling": "touch" }}
    return (

        <div
            className="relative flex min-h-full overflow-hidden flex-col"
        >

            <Joyride
                steps={steps}
                stepIndex={stepIndex}
                run={startTour}
                continuous={true}
                showProgress={true}
                showSkipButton={false}
                callback={handleJoyrideCallback}
                styles={{
                    options: {
                        arrowColor: '#6b21a8',
                        primaryColor: '#6b21a8'
                    },
                }}
            />

            <NavHeader currentPage={currentPage} mobileChatClasses={mobileChatClasses} />
            <div className={`mx-auto max-w-6xl flex flex-col md:flex-row w-screen items-start  gap-x-2 px-[5px] md:px-4 ${currentPage==='chat' && mobileChatClasses.indexOf('hidden') >= 0?'':'pt-[57px] bg-red-200' }  md:pt-[60px] `}>
                {showAsideLeft &&
                    <>
                        <AsideLeft shows={'leaderboard'} handleStartTour={handleStartTour} />
                        <AsideRight className="md:hidden" ads={ads} />
                    </>
                }
                <main className="flex-1 flex-grow self-stretch flex sm:max-w-[100vw] md:max-w-full ">
                    {props.children}
                </main>
                <AsideRight className="hidden md:block" ads={ads} />
            </div>
            <Footer currentPage={currentPage} mobileChatClasses={mobileChatClasses}  />
            <GoUpDown />
        </div>
    );
};

export default MainLayout;