import React, { useState, useEffect } from "react";
import CalendarIcon from "../icons/Calendar";
import PinIcon from "../icons/Pin";
import { Utils } from "../../utils";
import DollarCirle from "../icons/DollarCirle";
import Loader from 'react-loaders';
import { getIsJobSaved, saveJob, unSaveJob } from "../../core/job_listing";
import toast, { Toaster } from 'react-hot-toast';
import Bookmark from '../icons/Bookmark';
import { useNavigate } from "react-router-dom";

const JobHeader = ({ job, isOwner = false }) => {

    const navigate = useNavigate();

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [isSaved, setIsSaved] = useState(false);

    const checkJobSaved = async () => {
        // setIsLoading(true);
        const resp = await getIsJobSaved(job._id);
        console.log("is already saved:", resp);
        // setIsLoading(false);
        setIsSaved(resp);
    };

    useEffect(() => {
        if (loggedInUser && isOwner === false) {
            checkJobSaved();
        }
    }, []);

    const handleSaveJob = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);

        const inputs = {
            job_id: job._id
        };
        let response = await saveJob(inputs);
        setIsLoading(false);
        if (typeof response === 'object' && Array.isArray(response)) {
            return setErrors(response);
        }
        toast.success('Job Was Saved Successfully', {
            icon: '✅'
        });
        checkJobSaved();
    }

    const handleRemoveSavedJob = async (e) => {
        e.preventDefault();
        setErrors([]);
        setIsLoading(true);

        const inputs = {
            job_id: job._id
        };
        let response = await unSaveJob(inputs);
        setIsLoading(false);
        if (typeof response === 'object' && Array.isArray(response)) {
            return setErrors(response);
        }
        toast.success('Job Was Unsaved Successfully', {
            icon: '✅'
        });
        checkJobSaved();
    }

    return (
        <div className="relative px-3 md:px-6  pt-4 pb-1 bg-primary-white border-b border-gray-200  rounded-t-md ">
            <h1 className="mb-3 md:mb-1 text-md leading-6 font-bold text-gray-800 md:text-2xl md:mb-4 lg:text-3xl">
                {job.title}
            </h1>
            <div className="md:flex md:items-start md:justify-between mb-4">
                <div className="text-base text-brand  truncate sm:text-base md:text-lg lg:text-xl">
                    <div className="flex items-center text-gray-800  gap-x-2">
                        <div className="shrink-0 self-start">
                            <img
                                className="inline-block w-[48px] h-[48px] rounded-sm"
                                src={job.user.avatar}
                                alt={job.user.name}
                            />
                        </div>
                        <div className="truncate flex justify-between flex-col text-gray-800">
                            <span onClick={(e) => { e.stopPropagation(); navigate(`/profile/${job.user._id}`); }} className="capitalize text-sm font-bold leading-none text-purple-900">
                                {job.user.username} 
                            </span>
                            <span className="text-sm">{job.industry}</span>

                            <span className="text-xs flex ">
                                {/* <PinIcon className="h-4 w-4 mr-1 " aria-hidden="true" /> */}
                                {job.location_type.toLowerCase() !== "remote" ? `${job.location.city}, ${job.location.state}` : ``}
                            </span>

                        </div>
                    </div>

                </div>    
            </div>

            <div className="flex items-center text-xs">
                <DollarCirle className="h-3 w-3 mr-1" aria-hidden="true" />
                {job.compesation}
            </div>

            <div className="flex items-center justify-between ">
                <div className="flex items-center text-xs   ">
                    <CalendarIcon className="h-[15px] -ml-1" aria-hidden="true" />
                    <p className={`${job.display_status == 'active' ? 'text-green-700' : 'text-red-700'} capitalize  py-1 inline-flex text-xs font-bold rounded-full text md:text-xs`}>
                        {job.display_status}
                    </p>,
                    <div className="ml-1">
                        {`Posted ${Utils.formatDate(job.createdAt, "month date, year")}`}
                    </div>

                </div>
                <div className="text-xs sm:text-sm lg:text-base flex gap-x-2">


                </div>

            </div>
            <div className="flex flex-row items-center justify-between pt-4">

                {(loggedInUser && isOwner === false) &&
                    <button role="button"
                        aria-label={isSaved ? 'Unsave Job' : 'Save Job'}
                        type="button"
                        disabled={isLoading}
                        className="flex justify-center rounded-md py-1 text-xs font-bold gap-x-1  text-purple-900 shadow-sm hover:text-purple-800 disabled:cursor-not-allowed"
                        onClick={isSaved ? handleRemoveSavedJob : handleSaveJob}
                    >

                        <Bookmark />
                        {isSaved ? ' Unsave Job' : ' Save Job'}
                        {isLoading && <span>...</span>}
                    </button>
                }



                <div className="text-xs sm:text-sm lg:text-base flex gap-x-2">

                    <div className="text-xs sm:text-sm lg:text-base">
                        {job.job_type == "full-time" &&
                            <p className="bg-green-200 text-green-800 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                Full time
                            </p>
                        }
                        {job.job_type == "part-time" &&
                            <p className="bg-purple-200 text-purple-800 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                Part Time
                            </p>
                        }
                        {job.job_type == "contract-time" &&
                            <p className="bg-yellow-200 text-yellow-800 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                Contract
                            </p>
                        }
                    </div>
                    <div className="text-xs sm:text-sm lg:text-base">
                        {job.location_type.toLowerCase() === "on-site" &&
                            <p className="bg-cyan-200 text-cyan-800 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                On Site
                            </p>
                        }
                        {job.location_type.toLowerCase() === "hybrid" &&
                            <p className="bg-orange-200 text-orange-800 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                Hybrid
                            </p>
                        }
                        {job.location_type.toLowerCase() === "remote" &&
                            <p className="bg-lime-200 text-lime-800 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                Remote
                            </p>
                        }
                    </div>
                </div>

            </div>
        </div>
    );
};

export default JobHeader;