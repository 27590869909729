import React from "react";
import NavLogo from "./NavLogo"

const Footer = ({currentPage = "", mobileChatClasses = ""}) => {
    return (
        <nav className={`w-full min-w-[100%] flex flex-row items-center justify-center  pt-2 border-top mt-2 mb-[30px]  gap-12 ${currentPage=='chat'?mobileChatClasses:''} md:flex`  }>
                <NavLogo />
                <nav aria-label="Footer" className="flex flex-row ">
                    <h3 className="sr-only" id="sr-footer-heading">Footer navigation</h3>
                    <ul className="list-style-none flex flex-wrap justify-center gap-1 md:gap-6" >
                        <li className="mr-3 mr-lg-0 text-purple-900"><a href="/terms-of-use">Terms</a></li>
                        <li className="mr-3 mr-lg-0 text-purple-900"><a href="/privacy-policy" >Privacy</a></li>
                        {/* <li className="mr-3 mr-lg-0 text-purple-900"><a href="#">Security</a></li>
                        <li className="mr-3 mr-lg-0 text-purple-900"><a href="#" >Status</a></li>
                        <li className="mr-3 mr-lg-0 text-purple-900"><a href="#" >Training</a></li>
                        <li className="mr-3 mr-lg-0 text-purple-900"><a href="#" >Blog</a></li> */}
                        <li className="mr-3 mr-lg-0 text-purple-900"><a href="/about">About</a></li>
                    </ul>
                </nav>
        </nav>
    );
};


export default Footer;