import React, { useState } from "react";
import { Utils } from "../../utils";
import ReactTimeAgo from 'react-time-ago';
import MagnifyingGlassIcon from "../icons/MagnifyingGlass";
import PinIcon from "../icons/Pin";
import DollarCirle from "../icons/DollarCirle";
import Pagination from "../pagination/Pagination";
import { isAuthorised } from "../../utils/auth";
import Loader from 'react-loaders';
import { searchJobs } from '../../core/job_listing/index';
import EditIcon from "../icons/Edit";
import { useNavigate } from "react-router-dom";
import LoginAlertModal from "../auth/LoginAlertModal"

const JobListingCommunityAccordion = ({ community, isOpenInitially, isAdmin = false, limit = -1, lastJobBudgeDate = new Date() }) => {
    const navigate = useNavigate();

    const [isOpen, setIsOpen] = useState(isOpenInitially);
    const toggleAccordion = () => {
        if (isAdmin == false) {
            setIsOpen(!isOpen);
        }
    };

    const [errors, setErrors] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchLocationTerm, setSearchLocationTerm] = useState('');
    const [isOnSite, setIsOnSite] = useState('false');
    const [isHybrid, setIsHybrid] = useState('false');
    const [isRemote, setIsRemote] = useState('false');
    const [isFulltime, setIsFulltime] = useState('false');
    const [isPartTime, setIsPartTime] = useState('false');
    const [isContract, setIsContract] = useState('false');
    const [showMySavedJobs, setShowMySavedJobs] = useState('false');
    const [isVolunteer, setIsVolunteer] = useState("no");
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };
    const handleKeyDown = async (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            submitSearchForm(showMySavedJobs);
        }
    };
    const handleLocationChange = (event) => {
        setSearchLocationTerm(event.target.value);
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        submitSearchForm(showMySavedJobs);
    }
    const submitSearchForm = async (getMyySavedJobs = 'false') => {
        setErrors([]);
        setIsLoading(true);
        const inputs = {
            searchTerm,
            isOnSite,
            isHybrid,
            isRemote,
            isFulltime,
            isPartTime,
            isContract,
            showMySavedJobs: getMyySavedJobs,
            searchLocationTerm,
            isVolunteer
        };
        let response = await searchJobs(inputs);
        setIsLoading(false);
        if (!Array.isArray(response) || (Array.isArray(response) && response.length > 0 && typeof response[0] === 'string')) {
            return setErrors(response);
        }
        let searchedJobs = response ? response : [];
        if (limit !== -1) {
            searchedJobs = searchedJobs.slice(0, limit - 1);
        }
        setJobsList(searchedJobs);
    }

    let jobs = community.jobs ? community.jobs : [];
    if (limit !== -1) {
        jobs = jobs.slice(0, limit - 1);
    }

    let PageSize = 4;
    const [currentPage, setCurrentPage] = useState(1);
    const [jobsList, setJobsList] = useState(jobs);
    const [data, setData] = useState({
        length: jobsList ? jobsList.length : 0
    });
    const isOrg = isAuthorised(["organisation"]);

    const handleShowMySavedJobs = () => {
        setShowMySavedJobs("true");
        submitSearchForm("true");
    }

    const handleHideMySavedJobs = () => {
        setShowMySavedJobs("false");
        submitSearchForm("false");
    }

    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const [isLoginAlertModalOpen, setIsLoginAlertModalOpen] = useState(false);
    const toggleLoginAlertModalModal = () => {
        setIsLoginAlertModalOpen(!isLoginAlertModalOpen);
    };

    const handleGoToJob = (e, jobId) => {
        e.preventDefault();
        //href={isAdmin ? '#' : }
        if (loggedInUser) {
            navigate(`/jobs/${jobId}`);
        } else {
            //display dialog for user to login
            toggleLoginAlertModalModal()
        }
    }

    const handleGoToProfile = (job) => {
        //href={isAdmin ? '#' : }
        if (loggedInUser) {
            navigate(`/profile/${job.user._id}`);
        } else {
            //display dialog for user to login
            toggleLoginAlertModalModal()
        }
    }

    return (
        <div className="flex flex-col mb-2">

            <div className={`border-b rounded-t-md border-[#gray] flex flex-row ${isOrg ? "justify-between" : "justify-center"} items-center bg-primary-white flex flex-row flex-wrap text-sm font-bold pt-2 text-purple-900`}>

                <span className="pl-4">
                    {isOrg ? `MY` : ``} JOB & VOLUNTEER LISTINGS
                </span>

                {isOrg &&
                    <a
                        href="/jobs/add"
                        className="pointer flex flex-row gap-x-[2px] cursor-pointer rounded-sm text-purple-900 px-3 py-1.5 text-md font-bold hover:text-purple-800 "
                    >
                        <EditIcon />
                        Create Job Posting
                    </a>
                }
            </div>

            <form action="#" method="POST" onSubmit={handleSubmit} className="flex flex-col" >
                <div
                    className="bg-primary-white flex flex-row flex-wrap justify-between  text-white  px-3 py-2 cursor-pointer">
                    <div className="flex flex-col justify-between items-center flex-grow">
                        <div className="flex flex-col md:flex-row  w-full ">
                            <label htmlFor="search" className="sr-only">
                                Search Posted Jobs
                            </label>
                            <div className="relative text-gray-400 focus-within:text-gray-600 w-full" >
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2  -top-[75px] md:-top-[0px] ">
                                    <MagnifyingGlassIcon className="h-4 w-4" aria-hidden="true" />
                                </div>
                                <div className="flex flex-col md:flex-row ">
                                    <input
                                        id="searchjob"
                                        className=" block w-full md:rounded-l-full  border-1 border-gray-200  bg-gray-300 py-0.5 pl-8 pr-3 text-black mb-0  focus:border-purple-900 focus:ring-0 focus:ring-purple-900  md:text-[0.8rem]  md:text-md leading-8 md:leading-6  disabled:cursor-progress"
                                        placeholder="Title, Company Name or Skill ..."
                                        type="search"
                                        name="searchjob"
                                        onChange={handleChange}
                                        onKeyDown={handleKeyDown}
                                        value={searchTerm}
                                        disabled={isLoading}
                                        aria-label="search for jobs by title, Company Name or Skill etc"
                                    />
                                    <input
                                        id="searchlocationjob"
                                        className="mt-1 md:mt-0 block w-full  border-1 border-gray-200  bg-gray-300 py-0.5 pl-8 pr-3 text-black mb-0  focus:border-purple-900 focus:ring-0 focus:ring-purple-900  md:text-[0.8rem] md:text-md leading-8 md:leading-6 disabled:cursor-progress"
                                        placeholder="City, State, Zipcode ..."
                                        type="search"
                                        name="searchlocationjob"
                                        onChange={handleLocationChange}
                                        onKeyDown={handleKeyDown}
                                        value={searchLocationTerm}
                                        disabled={isLoading}
                                        aria-label="search for jobs by location city, state, zipcode ..."
                                    />
                                </div>
                                <button role="button"
                                    type="submit"
                                    disabled={isLoading}
                                    className="app-xs-md-h cursor-pointer mt-[5px] md:mt-[0px]  w-full md:w-auto flex justify-center items-center text-white md:absolute right-0 border border-purple-900 top-[0px] py-1 bg-purple-800 hover:bg-purple-700 font-sm md:rounded-r-full text-md md:text-sm px-4 ">
                                    Search
                                </button>
                            </div>
                        </div>
                        <div className="relative w-full">

                            {errors.length > 0 &&
                                <div className="app-error text-red-400 font-bold text-xs px-2">
                                    {errors[0]}
                                </div>
                            }
                            <div className="absolute  -top-6 right-20">
                                {isLoading && <Loader type="ball-beat" style={{ transform: 'scale(0.5)', color: 'blue' }} />}
                            </div>
                        </div>
                    </div>
                    <div className="cursor-pointer accent-text-color"></div>
                </div>


                {/* filters */}
                <div className="bg-primary-white w-full py-2 flex flex-col justify-between font-sans text-purple-900 not-italic font-medium text-sm leading-[120%]   text-ellipsis overflow-hidden whitespace-nowrap border-b border-b-[#gray] gap-y-2 md:gap-y-none" >

                    <div className="flex flex-row md:flex-row w-full items-center justify-between gap-y-2 md:gap-y-none">
                        <div className="flex flex-col md:flex-row gap-y-2 md:gap-y-none">
                            <div className="flex items-center ml-3">
                                <input
                                    id="filter-onsite"
                                    name="filter-onsite"
                                    type="checkbox"
                                    className="cursor-pointer h-4 w-4 bg-purple-900 text-purple-600 rounded border-gray-300 text-indigo-600 focus:ring-purple-600 mb-0 focus-outline-none disabled:cursor-progress"
                                    onChange={(e) => (e.target.checked) ? setIsOnSite("true") : setIsOnSite("false")}
                                    value="true"
                                    checked={"true" == isOnSite}
                                    disabled={isLoading}
                                />
                                <label htmlFor="filter-onsite" className="cursor-pointer ml-1 block text-md md:text-xs leading-6 text-gray-700 font-semibold">
                                    On Site
                                </label>
                            </div>
                            <div className="flex items-center ml-3 ">
                                <input
                                    id="filter-hybrid"
                                    name="filter-hybrid"
                                    type="checkbox"
                                    className="cursor-pointer h-4 w-4 bg-purple-900 text-purple-600 rounded border-gray-300 text-indigo-600 focus:ring-purple-600 mb-0 focus-outline-none disabled:cursor-progress"
                                    onChange={(e) => (e.target.checked) ? setIsHybrid("true") : setIsHybrid("false")}
                                    value="true"
                                    checked={"true" == isHybrid}
                                    disabled={isLoading}
                                />
                                <label htmlFor="filter-hybrid" className="cursor-pointer ml-1 block text-md md:text-xs leading-6 text-gray-700 font-bold">
                                    Hybrid
                                </label>
                            </div>
                            <div className="flex items-center ml-3">
                                <input
                                    id="filter-remote"
                                    name="filter-remote"
                                    type="checkbox"
                                    className="cursor-pointer h-4 w-4 bg-purple-900 text-purple-600 rounded border-gray-300 text-indigo-600 focus:ring-purple-600 mb-0 focus-outline-none disabled:cursor-progress"
                                    onChange={(e) => (e.target.checked) ? setIsRemote("true") : setIsRemote("false")}
                                    value="true"
                                    checked={"true" == isRemote}
                                    disabled={isLoading}
                                />
                                <label htmlFor="filter-remote" className="cursor-pointer ml-1 block text-md md:text-xs leading-6 text-gray-700 font-semibold">
                                    Remote
                                </label>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-y-2 md:gap-y-none">
                            <div className="flex items-center ml-3">
                                <input
                                    id="filter-fulltime"
                                    name="filter-fulltime"
                                    type="checkbox"
                                    className="cursor-pointer h-4 w-4 bg-purple-900 text-purple-600 rounded border-gray-300 text-indigo-600 focus:ring-purple-600 mb-0 focus-outline-none disabled:cursor-progress"
                                    onChange={(e) => (e.target.checked) ? setIsFulltime("true") : setIsFulltime("false")}
                                    value="true"
                                    checked={"true" == isFulltime}
                                    disabled={isLoading}
                                />
                                <label htmlFor="filter-fulltime" className="cursor-pointer ml-1 block text-md md:text-xs leading-6 text-gray-700 font-semibold">
                                    Full Time
                                </label>
                            </div>
                            <div className="flex items-center ml-3">
                                <input
                                    id="filter-perttime"
                                    name="filter-perttime"
                                    type="checkbox"
                                    className="cursor-pointer h-4 w-4 bg-purple-900 text-purple-600 rounded border-gray-300 text-indigo-600 focus:ring-purple-600 mb-0 focus-outline-none disabled:cursor-progress"
                                    onChange={(e) => (e.target.checked) ? setIsPartTime("true") : setIsPartTime("false")}
                                    value="true"
                                    checked={"true" == isPartTime}
                                    disabled={isLoading}
                                />
                                <label htmlFor="filter-perttime" className="cursor-pointer ml-1 block text-md md:text-xs leading-6 text-gray-700 font-semibold">
                                    Part Time
                                </label>
                            </div>
                            <div className="flex items-center  ml-3">
                                <input
                                    id="filter-contract"
                                    name="filter-contract"
                                    type="checkbox"
                                    className="cursor-pointer h-4 w-4 bg-purple-900 text-purple-600 rounded border-gray-300 text-indigo-600 focus:ring-purple-600 mb-0 focus-outline-none disabled:cursor-progress"
                                    onChange={(e) => (e.target.checked) ? setIsContract("true") : setIsContract("false")}
                                    value="true"
                                    checked={"true" == isContract}
                                    disabled={isLoading}
                                />
                                <label htmlFor="filter-contract" className="cursor-pointer ml-1 block text-md md:text-xs leading-6 text-gray-700 font-semibold">
                                    Contract
                                </label>
                            </div>
                        </div>

                        <div className="flex flex-col md:flex-row gap-y-2 md:gap-y-none">
                            <div className="flex items-center mr-4">
                                <input
                                    id="filter-my-saved-job"
                                    name="filter-my-saved-job"
                                    type="checkbox"
                                    className="cursor-pointer h-4 w-4 bg-purple-900 text-purple-600 rounded border-gray-300 text-indigo-600 focus:ring-purple-600 mb-0 focus-outline-none disabled:cursor-progress"
                                    onChange={(e) => (e.target.checked) ? handleShowMySavedJobs() : handleHideMySavedJobs()}
                                    value="true"
                                    checked={"true" == showMySavedJobs}
                                    disabled={isLoading}
                                />
                                <label htmlFor="filter-my-saved-job" className="cursor-pointer ml-1 block text-md md:text-xs leading-6 text-gray-700 font-semibold">
                                    My Saved Jobs
                                </label>
                            </div>
                        </div>
                    </div>

                    <div className="flex flex-row md:flex-row items-center justify-start">
                        <div className="flex items-center ml-3">
                            <input
                                id="filter-is-volunteer"
                                name="filter-is-volunteer"
                                type="checkbox"
                                className="cursor-pointer h-4 w-4 bg-purple-900 text-purple-600 rounded border-gray-300 text-indigo-600 focus:ring-purple-600 mb-0 focus-outline-none disabled:cursor-progress"
                                onChange={(e) => (e.target.checked) ? setIsVolunteer("yes") : setIsVolunteer("no")}
                                value={isVolunteer}
                                checked={"yes" == isVolunteer}
                                disabled={isLoading}
                            />
                            <label htmlFor="filter-is-volunteer" className="cursor-pointer ml-1 block text-md md:text-xs leading-6 text-gray-700 font-semibold">
                                Volunteer
                            </label>
                        </div>
                    </div>
                </div>


            </form>



            {/* list of jobs */}
            <div className={`flex flex-col bg-primary-white h-auto rounded-b-md`}  >

                {jobsList.length == 0 &&
                    <div className="flex justify-center py-12 text-gray-400 font-semibold lg:py-20">
                        <div className="flex flex-col items-center text-xs sm:text-sm md:text-base">
                            <div>
                                No Jobs found
                            </div>
                        </div>
                    </div>
                }

                <div className="rounded-b-md"  >
                    {jobsList.map((job, index) => (
                        <a key={index}

                            onClick={(e) => handleGoToJob(e, job._id)}
                            className="relative flex flex-col  border-gray-200 px-3 pt-3 pb-3 even:bg-white odd:bg-slate-100 hover:bg-purple-100 cursor-pointer last:rounded-b-md ">



                            <div className="flex flex-row justify-between">
                                <div className="w-[48px] h-[48px]  flex justify-center items-center text-purple-300">
                                    <img
                                        className="inline-block w-[48px] h-[48px] min-w-[48px] min-h-[48px] max-w-[48px] max-h-[48px] rounded-sm"
                                        src={job.user.avatar}
                                        alt=""
                                    />
                                </div>
                                <div className="flex-grow flex flex-col justify-between ml-2">
                                    <div className="flex flex-row justify-between items-center">
                                        <div onClick={(e) => { e.stopPropagation(); handleGoToProfile(job); }} className="text-xs text-gray-500 truncate text-md text-purple-900">
                                            {job.user.username}
                                        </div>
                                        <div className="flex items-center justify-between gap-x-2">

                                            <div className="shrink-0 text-xxs text-gray-600 text-xs md:text-md">
                                                {Utils.formatDate(job.createdAt, "month date, year")}
                                            </div>

                                            {Utils.isLessThanMonth(job.createdAt, lastJobBudgeDate) &&
                                                <div className="bg-red-600 text-white text-xs p-1 rounded-sm  right-1 top-1">
                                                    new
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div className="flex flex-row justify-between items-center">
                                        <span className="text-sm font-semibold font-medium">
                                            {job.title}
                                        </span>
                                    </div>
                                    <div className="text-xs font-semibold text-gray-600 sm:flex sm:items-center sm:justify-between md:text-xs">
                                        <div className="flex flex-col gap-y-0 md:gap-y-2 justify-start">
                                            <div className="flex items-start  mr-5 truncate text-xs border-gray-100 border-b md:border-0 pb-2 md:pb-0">
                                                {/* <PinIcon className="h-4 w-4 mr-1" aria-hidden="true" /> */}
                                                {job.location_type.toLowerCase() !== "remote" ?
                                                    `${job.location.city }, ${ job.location.state}` : ``
                                                }
                                            </div>
                                            <div className="flex items-center text-xs border-gray-300 border-t md:border-0 pt-2 md:pt-0">
                                                <DollarCirle className="h-4 w-4 mr-1" aria-hidden="true" />
                                                {job.compesation}
                                            </div>
                                        </div>
                                        <div className="mt-2 text-xs flex items-center font-normal space-x-1 sm:mt-0">
                                            <div className="md:ml-2 flex-shrink-0 flex">
                                                {job.job_type == "full-time" &&
                                                    <p className="bg-green-200 text-green-900 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                                        Full time
                                                    </p>
                                                }
                                                {job.job_type == "part-time" &&
                                                    <p className="bg-purple-200 text-purple-900 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                                        Part Time
                                                    </p>
                                                }
                                                {job.job_type == "contract-time" &&
                                                    <p className="bg-yellow-200 text-yellow-900 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                                        Contract
                                                    </p>
                                                }
                                            </div>



                                            <div className="ml-2 flex-shrink-0 flex">
                                                {job.location_type.toLowerCase() == "on-site" &&
                                                    <p className="bg-cyan-200 text-cyan-800 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                                        On Site
                                                    </p>
                                                }
                                                {job.location_type.toLowerCase() == "hybrid" &&
                                                    <p className="bg-orange-200 text-orange-800 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                                        Hybrid
                                                    </p>
                                                }
                                                {job.location_type.toLowerCase() == "remote" &&
                                                    <p className="bg-lime-200 text-lime-800 px-2 py-1 inline-flex text-xs font-semibold rounded-full md:text-xs">
                                                        Remote
                                                    </p>
                                                }
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            {isAdmin &&
                                <div className="flex justify-end mt-1 gap-x-2 pt-4 ">
                                    <a
                                        href={`/jobs/add?edit=${job.id}`}
                                        className="rounded-sm bg-purple-900 px-3 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-purple-800 "
                                    >
                                        Edit Job
                                    </a>

                                    <button role="button"
                                        type="button"
                                        className="relative inline-flex items-center rounded-sm bg-red-500 px-5 py-0.5 font-semibold text-white hover:bg-red-300 ">
                                        Delete
                                    </button>
                                </div>
                            }
                        </a>
                    ))}

                    <LoginAlertModal isOpen={isLoginAlertModalOpen} closeFun={setIsLoginAlertModalOpen} />
                </div>
                {/* <div className="py-1 text-sm pb-4 bg-primary-white">
                     <Pagination
                        id="pgn-bottom"
                        currentPage={currentPage}
                        totalCount={data.length}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                    /> 
                </div> */}


            </div>
        </div>
    );
}
export default JobListingCommunityAccordion;