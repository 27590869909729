import React from "react";

const NavLogo = () => {
    return (
        <div className="flex lg:flex-1x items-center" id="menu-link-log" >
            <a href="/"  className="-m-1.5 p-1.5 mr-1 menu-link-logo-cls">
                <span className="sr-only">wefayo</span>
                <img className="h-8 w-auto" src="/logo.png" alt="wefayo logo" /> 
            </a>
            <a href="/" className="menu-link-logo-cls hidden md:inline-block">
                <span className="text-xl">
                    <b className="logo-font menu-link-logo-cls">we</b><b className="logo-font logo-light menu-link-logo-cls">fayo</b>
                </span>
            </a>
        </div>
    );
};


export default NavLogo;