import React, { useState, useEffect, useRef } from "react";
import Loader from 'react-loaders';
import toast, { Toaster } from 'react-hot-toast';
import { useParams } from "react-router-dom";
import MainLayout from "../components/layouts/MainLayout";
import MagnifyingGlassIcon from "../components/icons/MagnifyingGlass";
import SingleTick from "../components/icons/SingleTick";
import SendIcon from "../components/icons/Send";
import { useNavigate } from "react-router-dom";
import { getUsersChatsList, searchUsersChatsList, sendUsersChatMessage } from "../core/chat";
import ChatListItemSkeleton from '../components/skeletons/ChatListItemSkeleton';
import NavUserAvatar from "../components/navigation/NavUserAvatar";
import ReactTimeAgo from 'react-time-ago';
import { useSocket } from '../utils/SocketProvider';
import EmojiConvertor from 'emoji-js';
import EmojiPicker from 'emoji-picker-react';
import EmojiIcon from "../components/icons/Emoji";
import PictureIcon from "../components/icons/Picture";
import Bars3Icon from '../components/icons/Bars3Icon';
import XMarkIcon from '../components/icons/XMarkIcon';

const emoji = new EmojiConvertor();
emoji.replace_mode = 'unified';

const Chat = () => {
    const { socket, sendMessageToChannel } = useSocket();

    const navigate = useNavigate();

    let params  = useParams();

    const [otherUserId, setOtherUserId] = useState(params?.other_user_id??"");

    
    let loggedInUserStr = sessionStorage.getItem("user");
    let loggedInUser = null;
    if (loggedInUserStr) {
        loggedInUser = JSON.parse(loggedInUserStr);
    }

    const [isLoading, setIsLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [chatsList, setChatsList] = useState([]);
    const [mobileShowRenderedChatList, setMobileShowRenderedChatList] = useState(true);
    const [renderedChatList, setRenderedChatList] = useState([]);
    const [searchResultsList, setSearchResultsList] = useState([]);
    const [showEmojiPicker, setShowEmojiPicker] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [selectedImagePreview, setSelectedImagePreview] = useState(null);
    const [caption, setCaption] = useState('');
    const CLOUDINARY_URL = process.env.REACT_APP_CLOUDINARY_URL || "";
    const CLOUDINARY_UPLOAD_PRESET = process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET || "";
    const [isFileUpLoading, setIsFileUpLoading] = useState(false);

    const [renderedChat, setRenderedChat] = useState(null);
    let initialMessageState = "";
    if (otherUserId !== "") {
        initialMessageState = {};
        initialMessageState[otherUserId] = "";
    }
    const [messageInputText, setMessageInputText] = useState(initialMessageState);
    const [isSendMessageLoading, setIsSendMessageLoading] = useState(false);
    const [sendMessageErrors, setSendMessageErrors] = useState([]);
    // Ref to target the chat div
    const chatContainerRef = useRef(null);


    const [searchChatListTerm, setSearchChatListTerm] = useState("");
    const [debouncedTerm, setDebouncedTerm] = useState('');
    const [isSearchLoading, setIsSearchLoading] = useState(false);

    const [isMedialViewDialogOpen, setIsMedialViewDialogOpen] = useState(false);
    const [medialViewDialogMedia, setMedialViewDialogMedia] = useState(null);

    const handleMessageMediaViewClick = (media) => {
        setMedialViewDialogMedia(media);
        setIsMedialViewDialogOpen(true);
    };

    const closeMediaViewDialog = () => {
        setMedialViewDialogMedia(null);
        setIsMedialViewDialogOpen(false);
    };

    function insertDateSeparators(messages) {
        if (messages.length === 0) return messages;

        const updatedMessages = [];
        let previousDate = null;

        messages.forEach((message, index) => {

            // Skip existing separators
            if (message.isSeparator) {
                updatedMessages.push(message);
                return;
            }

            const currentDate = new Date(message.send_date).toDateString();

            // Check if the current date is different from the previous message's date
            if (previousDate !== currentDate) {
                // Insert a separator message
                updatedMessages.push({
                    _id: `date-separator-${index}`,
                    message_text: ` ${currentDate} `,
                    isSeparator: true,
                    send_date: message.send_date
                });
                previousDate = currentDate;
            }

            // Insert the actual message, marking it as a non-separator
            updatedMessages.push({
                ...message,
                isSeparator: false
            });
        });

        return updatedMessages;
    }


    // Update debouncedTerm only after delay
    useEffect(() => {
        const timerId = setTimeout(() => {
            setDebouncedTerm(searchChatListTerm);
        }, 500); // 500ms delay

        return () => {
            clearTimeout(timerId);
        };
    }, [searchChatListTerm]);
    // Trigger API call whenever debouncedTerm changes
    useEffect(() => {
        if (debouncedTerm) {
            fetchSearchResults(debouncedTerm);
        }
    }, [debouncedTerm]);
    // Fetch function (replace with your API call)
    const fetchSearchResults = async (term) => {
        if (term.length > 0) {
            setIsSearchLoading(true);
            setSearchResultsList([]);
            const data = await searchUsersChatsList({
                searchTerm: term
            });
            setIsSearchLoading(false);
            // console.log("Search results:", data);
            setSearchResultsList([...data]);
        } else {
            //render back all the previous chats list before searching
            setSearchResultsList([]);
        }
    };

    const fetchData = async (withUserId) => {
        setIsLoading(true);
        const data = await getUsersChatsList(withUserId);
        // console.log("data", data); //{list: Array(0), un_seen_count: 0, with: null}
        setIsLoading(false);
        setChatsList(data.list);
        setRenderedChatList(data.list);
        // setOtherUserId(withUserId);
        if (data.with !== null) {
            updateRenderedChat(data.with)
            setMobileShowRenderedChatList(false)
            // todo: delete commented below if refactors are working okay
            // setRenderedChat(data.with)
            // setTimeout(() => {
            //     scrollChatIntoView();
            // }, 100);
        }
    }

    const updateRenderedChat = (chatToRender, newMessage = null) => {
        let msgs = chatToRender?.messages ?? [];
        if (newMessage) {
            msgs.push(newMessage);
        }
        const separatedMsgs = insertDateSeparators(msgs);
        const updatedChat = {
            ...chatToRender,
            messages: separatedMsgs
        }
        setRenderedChat(updatedChat);
        return updatedChat;
    }

    // chat box things
    const textAreaRef = useRef(null);
    const maxHeight = 150; // Set maximum height
    const handleMessageInputChange = (event) => {
        const value = event.target.value;
        console.log("The value:", value);
        if (otherUserId === "") {
            setMessageInputText(value);
        } else {
            let updatedMsgInput = {};
            if (typeof messageInputText != 'object') {
                updatedMsgInput = {};
            } else {
                updatedMsgInput = {
                    ...messageInputText
                }
            }
            updatedMsgInput[otherUserId] = value;
            setMessageInputText(updatedMsgInput);
        }

        // Reset height to calculate new scrollHeight
        textAreaRef.current.style.height = "auto";

        // Set the height to the scrollHeight, capped at maxHeight
        const h = value.length === 0 ? 60 : textAreaRef.current.scrollHeight;
        textAreaRef.current.style.height = Math.min(h, maxHeight) + "px";
    };
    const handleMessageInputKeyDown = async (event) => {
        if (event.key === "Enter" && event.shiftKey) {
            event.stopPropagation(); // Stop any propagation
            event.preventDefault();  // Prevent the default action of sending


            if (otherUserId === "") {
                setMessageInputText(messageInputText + "\n");
            } else {
                let updatedMsgInput = {};
                if (typeof messageInputText != 'object') {
                    updatedMsgInput = {};
                    updatedMsgInput[otherUserId] = "\n";
                } else {

                    if (Object.prototype.hasOwnProperty.call(messageInputText, otherUserId)) {
                        const current = messageInputText[otherUserId] + "\n";
                        updatedMsgInput = { ...messageInputText };
                        updatedMsgInput[otherUserId] = current;
                    } else {
                        updatedMsgInput = { ...messageInputText };
                        updatedMsgInput[otherUserId] = "\n";
                    }

                }
                setMessageInputText(updatedMsgInput);
            }
        } else if (event.key === 'Enter') {
            // send the user a chat message
            await sendMessage();
        }
    };
    const handleEmojiClick = (emojiObject) => {
        handleMessageInputChange({
            target: {
                value: (messageInputText[otherUserId] || '') + emojiObject.emoji
            }
        });
        setShowEmojiPicker(false); // Close picker after selection
    };
    const toggleEmojiPicker = () => {
        setShowEmojiPicker((prev) => !prev);
    };
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImagePreview(URL.createObjectURL(file)); // Preview image
            setSelectedImage(file);
        }
    };
    const handleAcceptImage = async () => {
        // Process the image and caption (e.g., send to server)
        console.log('Image:', selectedImagePreview);
        console.log('Caption:', caption);
        //upload the image
        setIsFileUpLoading(true);
        const formData = new FormData();
        formData.append('file', selectedImage);
        formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET);
        const data = await fetch(CLOUDINARY_URL, {
            method: "POST",
            body: formData
        })
            .then(r => r.json())
            .then((data) => {
                // setSelectedImage();
                sendMessage({ media: data.secure_url, caption: caption });
                handleCancelImage();
            }).finally(() => {
                setIsFileUpLoading(false);
            });
    };
    const handleCancelImage = () => {
        setSelectedImage(null);
        setSelectedImagePreview(null);
        setCaption('');
    };
    const sendMessage = async (mediaAttachment) => {
        setSendMessageErrors([]);
        setIsSendMessageLoading(true);
        let trimmedMessage = "";
        if (typeof messageInputText != 'object') {
            trimmedMessage = messageInputText.trim();
        } else {
            if (Object.prototype.hasOwnProperty.call(messageInputText, otherUserId)) {
                const current = messageInputText[otherUserId];
                trimmedMessage = current.trim();
            } else {
                trimmedMessage = "";
            }
        }
        const media = mediaAttachment?.media ?? "";
        if (media.length > 0) {
            trimmedMessage = mediaAttachment?.caption.trim() ?? "";
        }
        if (trimmedMessage.length > 0 || (media && media.length > 0)) {
            trimmedMessage = emoji.replace_colons(trimmedMessage);

            // first insert a raw message
            const raw = {
                _id: "",
                is_receiver_deleted: false,
                is_sender_deleted: false,
                media: media,
                message_id: renderedChat.messages.length + 1,
                message_text: trimmedMessage,
                receiver_icon_reaction: "",
                receiver_id: otherUserId,
                send_date: (new Date()).toISOString(),
                sender_icon_reaction: "",
                sender_id: loggedInUser._id,
                status: "sending"
            }
            const updatedChat = updateRenderedChat(renderedChat, raw)

            // todo: delete below comments if things are working fine
            // const updatedChat = {
            //     ...renderedChat,
            //     messages: [
            //         ...renderedChat.messages,
            //         raw
            //     ]
            // }

            // setRenderedChat(updatedChat);
            // setTimeout(() => {
            // scrollChatIntoView();
            setTimeout(async () => {
                await sendFormattedMessage(trimmedMessage, updatedChat, media)
            }, 100);
            // }, 50);
        } else {
            setIsSendMessageLoading(false);
            handleCancelImage();
        }
    }

    const sendFormattedMessage = async (trimmedMessage, chatToUpdate, mediaUrl) => {

        if (trimmedMessage.length === 0 && mediaUrl.length > 0) {
            // allows to send a media message without any texts or captions
            trimmedMessage = " ";
        }

        const data = await sendUsersChatMessage({
            message_text: trimmedMessage,
            other_user_id: otherUserId,
            media: mediaUrl
        });
        if (Array.isArray(data) && data.length > 0) {
            handleSendError(data);
        } else {
            setIsSendMessageLoading(false)

            handleMessageInputChange({
                target: {
                    value: ""
                }
            });
            // insert the new message into the messages array
            const messages = chatToUpdate.messages.map((x) => {
                if (x.status === "sending") {
                    return data;
                } else {
                    return x;
                }
            });

            updateRenderedChat({
                ...chatToUpdate,
                messages: messages
            });

            handleCancelImage();

            // todo: delete if refactor is working fine
            // setRenderedChat({
            //     ...chatToUpdate,
            //     messages: messages
            // });
            // setTimeout(() => {
            //     scrollChatIntoView();
            // }, 100);

            let senderUser = null;
            if (renderedChat.other_user._id == loggedInUser._id) {
                senderUser = renderedChat.other_user;
            } else {
                senderUser = renderedChat.user;
            }

            const outGoingRealtimeMessage = {
                ...data,
                sender: { ...senderUser },
                rendered_chat_id: renderedChat._id
            };
            sendMessageToChannel(otherUserId, outGoingRealtimeMessage);
            updateLeftChatList(outGoingRealtimeMessage, "outgoing");

        }
    }

    const handleSendError = (errorData) => {
        setIsSendMessageLoading(false)
        // error, remove the temp message
        const messages = renderedChat.messages.filter((x) => {
            return x.status !== "sending"
        });
        updateRenderedChat({
            ...renderedChat,
            messages: messages
        })

        // todo: delete below if refactor is working fine
        // setRenderedChat({
        //     ...renderedChat,
        //     messages: messages
        // });
        setSendMessageErrors(errorData);
        // console.log("errorData", errorData);
        // setTimeout(() => {
        //     scrollChatIntoView();
        // }, 100);
    }

    const scrollChatIntoView = () => {
        // chatEndRef.current?.scrollIntoView({ behavior: 'smooth' });
        const chatContainer = chatContainerRef.current;
        if (chatContainer) {
            chatContainer.scrollTop = chatContainer.scrollHeight;
        }
    }

    const handleLoadChat = (event, peersUserId) => {
        event.stopPropagation(); // Stop any propagation
        event.preventDefault();  // Prevent the default action of sending
        fetchData(peersUserId);
        setOtherUserId((prev_id) => (peersUserId));
        console.log("Before Nav:", otherUserId);
        navigate(`/chat/${peersUserId}`);
        console.log("After Nav:", otherUserId);
    }

    useEffect(() => {
        if (loggedInUser === null || (loggedInUser !== null && loggedInUser._id == otherUserId)) {
            // Redirect to the communities page if the user is not logged in
            navigate(`/`);
        } else {
            fetchData(otherUserId);
        }
    }, []);

    const updateLeftChatList = (incomingData) => {
        setRenderedChatList((currentRenderedChatList) => {
            let hasBeenInserted = false;
            for (let index = 0; index < currentRenderedChatList.length; index++) {
                const chatItem = currentRenderedChatList[index];
                if (chatItem._id === incomingData.rendered_chat_id) {
                    chatItem.messages = [...chatItem.messages, incomingData];
                    chatItem.last_message_text = incomingData.message_text;
                    chatItem.last_updated_date = incomingData.send_date;
                    currentRenderedChatList[index] = chatItem;
                    hasBeenInserted = true;
                    break;
                }
            }
            if (hasBeenInserted === false) {
                // insert on top of the list the new chat item
                currentRenderedChatList = [{
                    user: { ...loggedInUser },
                    other_user: incomingData.sender,
                    messages: [incomingData],
                    is_user_deleted: false,
                    is_other_user_deleted: false,
                    last_message_text: incomingData.message_text,
                    last_updated_date: incomingData.send_date
                }, ...currentRenderedChatList]
            }
            console.log("hasBeenInserted:", hasBeenInserted);
            return [...currentRenderedChatList];
        });
    }

    const updateChatWithRetry = (count, incomingData) => {
        if (count === 10) {
            console.error(`Failed to update chat with new incoming message after ${count} retries`, incomingData);
            return false;
        }

        setRenderedChat((currentRenderedChat) => {
            if (currentRenderedChat) {
                // check if this message belongs to this chat, the sender_id must be the same as the otherUserId
                if (incomingData.rendered_chat_id === currentRenderedChat._id) {
                    // Update chat state with the new message
                    const msgs = [...currentRenderedChat.messages, incomingData];
                    const separatedMessages = insertDateSeparators(msgs)
                    const updatedChat = {
                        ...currentRenderedChat,
                        messages: separatedMessages
                    };
                    return updatedChat;
                } else {
                    return currentRenderedChat;
                }
            } else {
                // Retry after a short delay
                setTimeout(() => updateChatWithRetry(count + 1), 100);
                return currentRenderedChat;
            }
        });

        // Scroll chat into view
        // setTimeout(() => {
        //     scrollChatIntoView();
        // }, 100);
    };

    useEffect(() => {

        if (!socket) return;

        // // Listen for incoming messages
        socket?.on('receiveMessage', (incomingData) => {
            // Log the received message data to the console
            console.log("otherUserId before Loading", otherUserId);
            console.log("incomingData", incomingData);

            //if there is not chat selected then auto select the incomings id
            if (otherUserId === "") {
                // not complete
                // navigate(`/chat/${incomingData.sender_id}`);
                // fetchData(incomingData.sender_id);

                handleLoadChat({
                    stopPropagation: () => { },
                    preventDefault: () => { }
                }, incomingData.sender_id);
            } else {
                //for one of the chats on the right or a brand new message
                updateLeftChatList(incomingData);

                // Start the retry function
                updateChatWithRetry(1, incomingData);
            }
        });

        // Clean up the listener on component unmount
        return () => {
            socket?.off('receiveMessage');
        };
    }, [socket, setRenderedChat, otherUserId, setOtherUserId]);

    // Trigger scroll when `renderedChat` is updated
    useEffect(() => {
        if (renderedChat?.messages) {
            // Ensure chat is scrolled into view after messages update
            setTimeout(() => {
                scrollChatIntoView();
            }, 100);
        }
    }, [renderedChat]);



    return (
        
        <MainLayout currentPage={"chat"} showAsideLeft={false} mobileChatClasses={mobileShowRenderedChatList == false && renderedChat !== null?'hidden':''} >
            <div className="flex flex-row flex-1 gap-y-2 gap-x-2  bg-[#f0f2f6] rounded-md  lg:max-h-full xl:max-h-[1095px] overflow-y-hidden  w-full">
                <div className={`${mobileShowRenderedChatList ? "block" : "hidden md:block"} flex flex-col w-full md:w-[300px] min-h-[calc(100vh-62px)] md:min-h-none bg-white rounded-l-md `}>
                    <div className="flex flex-row w-full justify-between align-center  pt-2 md:pt-1 pb-2 md:pb-0 px-2">
                        <h1 className="text-md font-bold ">Chats</h1>
                        {/* close mobile chat list */}
                        {otherUserId !== "" &&
                            <button role="button"
                                type="button"
                                className="md:hidden flex items-center justify-center rounded-md p-2.5 text-primary-gray-700"
                                onClick={() => setMobileShowRenderedChatList(false)}
                                id="menu-close-chat-list-btn-mobile-menu-hamburger"
                            >
                                <span className="sr-only">Close Chats List</span>
                                <XMarkIcon aria-hidden="true" />
                            </button>
                        }
                    </div>
                    <div className="flex flex-col w-full justify-between align-center  px-2 pt-1">
                        <div className="flex w-full">
                            <label htmlFor="search" className="sr-only">
                                Search
                            </label>
                            <div className="w-full relative text-gray-400 focus-within:text-gray-600" >
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                                    <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                                </div>
                                <input
                                    id="search"
                                    className="block w-full rounded-full border-1 border-gray-200 bg-gray-300 py-0.5 pl-8 pr-3 text-gray mb-0 focus:border-purple-900 focus:ring-0 focus:ring-purple-900  sm:text-sm sm:leading-6"
                                    placeholder="Search"
                                    type="search"
                                    name="search"
                                    value={searchChatListTerm}
                                    onChange={(e) => setSearchChatListTerm(e.target.value)}
                                />
                            </div>
                        </div>
                        {(isSearchLoading === false && searchChatListTerm.length > 0 && renderedChatList.length > 0) &&
                            <div className="text-xs pl-2 pt-1">
                                Found <b>{renderedChatList.length}</b> Result{renderedChatList.length != 1 ? 's' : ''}
                            </div>
                        }
                    </div>
                    <div className="flex flex-col p-2  max-w-full min-w-[275px] overflow-y-auto">

                        {/* start searching */}

                        {isSearchLoading &&
                            <>
                                <ChatListItemSkeleton />
                                <ChatListItemSkeleton />
                                <ChatListItemSkeleton />
                                <ChatListItemSkeleton />
                            </>
                        }

                        {(isSearchLoading === false && searchChatListTerm.length > 0 && searchResultsList.length == 0) &&
                            <div className="flex justify-center py-12 text-gray-400 font-semibold lg:py-20 bg-white w-full" >
                                <div className="flex flex-col items-center text-xs sm:text-sm md:text-base">
                                    <div className="text-center">
                                        No Search Results For <br />
                                        <small className="text-xs">{searchChatListTerm}</small>
                                    </div>
                                </div>
                            </div>
                        }

                        {searchChatListTerm.length > 0 && searchResultsList.map((user, index) => {
                            return (
                                <div key={index} className={`p-2 md:min-w-[120px] mr-2 even:bg-white odd:bg-slate-100 relative flex flex-row gap-x-2 md:mr-0 justify-start hover:bg-primary-gray-50 cursor-pointer`}>

                                    <NavUserAvatar user={user} link={"#"} imgSizeClass={`w-[40px] h-[40px] max-w-[40px] max-h-[40px]'} block`} />


                                    <a href={`/chat/${user._id}`} className=" flex flex-col justify-between flex-grow ">
                                        <div className={`text-xs font-semibold leading-6x text-primary-gray-900`}>
                                            <div className="whitespace-nowrap ">
                                                <div>{user?.username ?? ''}</div>
                                                {/* <div className="absolute inset-x-2 top-[4px] bottom-0 font-bold w-fit" >
                                            {index+1}
                                        </div> */}
                                            </div>
                                        </div>
                                        <div className={`mt-1 text-xs flex leading-1 text-gray-500 whitespace-nowrap`}>
                                            member since {user?.join_date?.split("T")[0] ?? ''}
                                        </div>
                                    </a>
                                </div>
                            )
                        })}

                        {/* end searching */}

                        {(isSearchLoading === false && searchChatListTerm.length === 0) && renderedChatList.map((chatListItem, index) => {
                            const isActive = chatListItem.other_user._id == otherUserId || chatListItem.user._id == otherUserId;
                            let peerUser = null;
                            if (chatListItem.other_user._id == loggedInUser._id) {
                                // here loggedInUser was the other_user
                                peerUser = chatListItem.user;
                            } else {
                                peerUser = chatListItem.other_user;
                            }
                            return (
                                <div className={"flex flex-row max-w-full items-center justify-between gap-x-2 cursor-pointer pl-2  hover:bg-purple-100 " + (isActive ? 'bg-purple-200' : 'even:bg-white odd:bg-slate-100')}>
                                    {/* <div className="w-[48px] h-[48px] max-w-[48px] max-h-[48px]">
                                        <div class="min-w-full min-h-full w-[48px] h-[48px] bg-no-repeat bg-center bg-cover"
                                            style={{ backgroundImage: "url('https://res.cloudinary.com/dbjx623yn/image/upload/v1703093120/wefayo-app-image-uploads/cqn3nq0vgl7ml7nitbjv.jpg')" }} >
                                        </div>
                                    </div> */}

                                    <NavUserAvatar user={peerUser} link={"#"} imgSizeClass={`w-[40px] h-[40px] max-w-[40px] max-h-[40px]'} block`} />

                                    <div onClick={(e) => { handleLoadChat(e, peerUser._id) }} className="min-h-full  flex flex-col justify-between flex-grow border-b border-gray-300 pr-2 py-2 relative">
                                        <div className="flex flex-row justify-between items-center">
                                            <span className="text-sm font-bold overflow-hidden whitespace-nowrap text-nowrap w-[120px]">{peerUser.username}</span>
                                            <div className="flex flex-row gap-x-1">
                                                <span className="text-xs text-gray-700  whitespace-nowrap text-nowrap">
                                                    <ReactTimeAgo date={chatListItem.last_updated_date} locale="en-US" />
                                                </span>
                                                {chatListItem.un_seen_count > 0 &&
                                                    <div className={`w-fit absolute bg-red-600 h-[15px] flex items-center text-white rounded-full right-[0px] -top-[-35px] p-1`} style={{ fontSize: "8px" }} >
                                                        {chatListItem.un_seen_count}
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="flex flex-row justify-start items-center pt-1 gap-x-1">
                                            {/* <SingleTick className="w-3 pt-[2px]" /> */}
                                            <div className="overflow-hidden whitespace-nowrap text-nowrap text-sm w-[220px]" >
                                                {chatListItem.last_message_text}
                                            </div>
                                        </div>
                                        {/* <small>{chatListItem._id}</small> */}
                                    </div>
                                </div>
                            )
                        })}

                        {(searchChatListTerm.length === 0 && renderedChatList.length == 0) &&

                            <div className="flex justify-center py-12 text-gray-400 font-semibold lg:py-20 bg-white w-full" >
                                <div className="flex flex-col items-center text-xs sm:text-sm md:text-base">
                                    <div className="text-center">
                                        No Chats <br />
                                        <small>Get Started By Searching</small>
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                <div className={`${otherUserId === "" ? "hidden md:block" : (mobileShowRenderedChatList ? "hidden" : "block h-screen")}  flex flex-col flex-1 rounded-r-md pl-1 md:pl-2 md:pl-0 pr-1`}>

                    {(isLoading || renderedChat === null) &&
                        <div className="flex flex-col max-h-[calc(100vh-70px)] h-[calc(100vh-70px)] w-full ">
                            <div className="bg-[#f0f2f5] max-w-full rounded-r-md p-2 flex flex-row justify-between items-center">
                                {/* selected user */}
                                <div className="flex flex-row max-w-full items-center justify-between gap-x-2 cursor-pointer ">
                                    <div className="w-[48px] h-[48px] max-w-[48px] max-h-[48px]">
                                        <div class="min-w-full min-h-full w-[48px] h-[48px] bg-no-repeat bg-center bg-cover bg-gray-200" >

                                        </div>
                                    </div>
                                    <div className="flex flex-col justify-between flex-grow gap-y-2">
                                        <span className="text-sm font-bold overflow-hidden whitespace-nowrap text-nowrap min-w-[350px] w-[350px] h-4 bg-gray-200"></span>
                                        <div className="flex flex-row gap-x-1 ">
                                            <span className="text-xs text-gray-700 min-w-[150px] w-[150px] h-4 bg-gray-200 ">

                                            </span>
                                        </div>
                                    </div>
                                </div>
                                {/* search chat */}
                                <div className="hidden flex w-[300px]">
                                    {/*    this is hidden for now
                                    
                                    <label htmlFor="search" className="sr-only">
                                        Search
                                    </label>
                                    <div className="w-full relative text-gray-400 focus-within:text-gray-600" >
                                        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                                            <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                                        </div>
                                        <input
                                            id="search"
                                            className="block w-full rounded-full border-1 border-gray-200 bg-gray-300 py-0.5 pl-8 pr-3 text-gray mb-0 focus:border-purple-900 focus:ring-0 focus:ring-purple-900  sm:text-sm sm:leading-6"
                                            placeholder="Search"
                                            type="search"
                                            name="search"
                                            onKeyDown={}
                                            onChange={}
                                            value={}
                                        />
                                    </div> */}
                                </div>
                            </div>
                            <div className="flex-1 p-2 xbg-[#efeae2] bg-white w-full flex flex-col item-center h-full rounded-md">

                                <div className="hidden w-full flex justify-end px-4 mt-2">
                                    <div className="flex flex-col bg-[#d7d3cb]/40 p-1 max-w-[75%] rounded-md">
                                        <div className="p-1 text-[#d9fdd2] h-20 w-[500px]"></div>
                                        <span className="text-xs self-end text-gray-400 flex flex-row gap-x-1 h-2 w-[500px]"></span>
                                    </div>
                                </div>

                                <div className="hidden w-full flex justify-start px-4 mt-2">
                                    <div className="flex flex-col bg-white/50 p-1 max-w-[75%] rounded-md">
                                        <div className="p-1 h-20 w-[500px]"></div>
                                        <div className="text-xs self-end  text-gray-400 h-2 w-[500px]"> </div>
                                    </div>
                                </div>

                                <div className="w-full text-center p-10 font-semibold text-gray-400 flex-1 flex flex-col justify-center items-center ">
                                    {isLoading && <> <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} /> Loading chat</>}
                                    {!isLoading && <>No Chat Selected</>}
                                </div>

                                <div className="hidden w-full flex justify-start px-4 mt-2">
                                    <div className="flex flex-col bg-white/50 p-1 max-w-[75%] rounded-md">
                                        <div className="p-1 h-20 w-[500px]">
                                        </div>
                                        <div className="text-xs self-end  text-gray-400 h-2 w-[500px]"></div>
                                    </div>
                                </div>

                                <div className="hidden w-full flex justify-end px-4 mt-2">
                                    <div className="flex flex-col bg-[#d7d3cb]/40 p-1 max-w-[75%] rounded-md">
                                        <div className="p-1 text-[#d9fdd2] h-20 w-[500px]"></div>
                                        <span className="text-xs self-end text-gray-400 flex flex-row gap-x-1 h-2 w-[500px]"></span>
                                    </div>
                                </div>
                            </div>
                            <div className="bg-[#f0f2f5] rounded-br-md flex flex-row items-center justify-center px-4">
                                <div className="flex-grow p-2 flex flex-row items-center justify-center ">
                                    <input
                                        type="text"
                                        name="message-input"
                                        id="message-input"
                                        autoComplete="message-input"
                                        disabled={true}
                                        placeholder=""
                                        className="mb-0 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 disabled:bg-gray-900"
                                    />
                                </div>
                            </div>
                        </div>
                    }

                   

                    {(isLoading == false && renderedChat !== null) && (() => {
                        let driverUser = null;
                        let peerUser = null;
                        if (renderedChat.other_user._id == loggedInUser._id) {
                            // here loggedInUser was the other_user
                            peerUser = renderedChat.user;
                            driverUser = renderedChat.other_user;
                        } else {
                            peerUser = renderedChat.other_user;
                            driverUser = renderedChat.user;
                        }
                        // overflow-y-hidden, 
                        return (
                            <div className="flex flex-col max-h-[100vh] md:max-h-[calc(100vh-670px)] h-[100vh] md:h-[calc(100vh-570px)] w-full absolute md:static top-0 left-0">
                                <div className="flex flex-col static  h-screen h-screen " >
                                    {/* The header section */}
                                    <div className="bg-[#f0f2f5] max-w-full md:rounded-r-md  p-2 flex flex-row justify-between items-center">
                                        {/* selected user */}
                                        <div className="flex flex-row max-w-full items-center justify-between gap-x-2 cursor-pointer ">
                                            {/* <div className="w-[48px] h-[48px] max-w-[48px] max-h-[48px]">
                                            <div class="min-w-full min-h-full w-[48px] h-[48px] bg-no-repeat bg-center bg-cover bg-gray-400"
                                                style={{ backgroundImage: "url('https://res.cloudinary.com/dbjx623yn/image/upload/v1703093120/wefayo-app-image-uploads/cqn3nq0vgl7ml7nitbjv.jpg')" }} >
                                            </div>
                                        </div> */}
                                            <NavUserAvatar user={peerUser} link={"#"} imgSizeClass={`w-[40px] h-[40px] max-w-[40px] max-h-[40px]'} block`} />
                                            <div className="flex flex-col justify-between flex-grow">
                                                <span className="text-sm font-bold overflow-hidden whitespace-nowrap text-nowrap w-[150px]">{peerUser.username}</span>
                                                <div className="flex flex-row gap-x-1">
                                                    <span className="text-xs text-gray-700 ">
                                                        <ReactTimeAgo date={renderedChat.last_updated_date} locale="en-US" />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* search chat */}
                                        <div className="hidden flex w-[300px]">
                                            {/*    this is hidden for now
                                        
                                        <label htmlFor="search" className="sr-only">
                                            Search
                                        </label>
                                        <div className="w-full relative text-gray-400 focus-within:text-gray-600" >
                                            <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-2">
                                                <MagnifyingGlassIcon className="h-5 w-5" aria-hidden="true" />
                                            </div>
                                            <input
                                                id="search"
                                                className="block w-full rounded-full border-1 border-gray-200 bg-gray-300 py-0.5 pl-8 pr-3 text-gray mb-0 focus:border-purple-900 focus:ring-0 focus:ring-purple-900  sm:text-sm sm:leading-6"
                                                placeholder="Search"
                                                type="search"
                                                name="search"
                                                onKeyDown={}
                                                onChange={}
                                                value={}
                                            />
                                        </div> */}
                                        </div>
                                        {/* mobile menu */}
                                        <button role="button"
                                            type="button"
                                            className="block md:hidden flex items-center justify-center rounded-md p-2.5 text-primary-gray-700"
                                            onClick={() => setMobileShowRenderedChatList(!mobileShowRenderedChatList)}
                                            id="menu-chat-btn-mobile-menu-hamburger"
                                        >
                                            <span className="sr-only">Open Chats List</span>
                                            {mobileShowRenderedChatList &&
                                                <XMarkIcon aria-hidden="true" />
                                            }
                                            {!mobileShowRenderedChatList &&
                                                <Bars3Icon aria-hidden="true" />
                                            }
                                        </button>
                                    </div>
                                    {/* The body section */}
                                    <div ref={chatContainerRef} className="static flex flex-col p-2 bg-white md:rounded-md w-full flex-grow overflow-y-auto">
                                        {renderedChat.messages.map((messageItem, index) => {
                                            if (messageItem.isSeparator) {
                                                return (
                                                    <div className={`w-full flex px-4 gap-x-1 justify-center my-8 text-gray-300`} key={'msg' + index}>
                                                        --- <div className="p-1 bg-white rounded-md text-xs text-gray-500 shadow-md px-10">{messageItem.message_text}</div> ---
                                                    </div>
                                                )
                                            } else {
                                                const isDriversMessage = messageItem.sender_id === loggedInUser._id;
                                                const justify = isDriversMessage ? 'justify-end' : 'justify-start';
                                                const bgColor = messageItem.sender_id === loggedInUser._id ? 'bg-[#d9fdd2]' : 'bg-purple-100';
                                                const fromName = isDriversMessage ? driverUser.username : peerUser.username;
                                                let statusForThisMessage = "";
                                                if (messageItem.status === 'received') {
                                                    statusForThisMessage = "sent";
                                                } else {
                                                    statusForThisMessage = "not sent";
                                                    if (isSendMessageLoading && messageItem._id === "") {
                                                        statusForThisMessage = "sending";
                                                    }
                                                }
                                                return (
                                                    <div className={`w-full flex px-4 mt-2 gap-x-1 ${justify}`} key={'msg' + index}>
                                                        {(isDriversMessage === false) &&
                                                            <NavUserAvatar user={peerUser} link={"#"} imgSizeClass={`w-[30px] h-[30px] max-w-[30px] max-h-[30px]'} block`} />
                                                        }
                                                        <div className={`flex flex-col  p-1 max-w-[75%] rounded-md ${bgColor}`}>
                                                            {messageItem.media && (
                                                                <img
                                                                    src={messageItem.media}
                                                                    alt="Media Preview"
                                                                    className="mb-2 rounded-md cursor-pointer max-h-[200px] object-cover"
                                                                    onClick={() => handleMessageMediaViewClick(messageItem.media)}
                                                                />
                                                            )}
                                                            <div className="p-1">{messageItem.message_text}</div>
                                                            <div className="flex flex-row items-center justify-between">
                                                                <span className="text-xs self-end text-gray-400 flex flex-row gap-x-1 mr-2">
                                                                    {statusForThisMessage}
                                                                </span>
                                                                <span className="text-xs self-end text-gray-400 flex flex-row gap-x-1">
                                                                    <ReactTimeAgo date={messageItem.send_date} locale="en-US" />
                                                                    {/* <SingleTick className="w-3 pt-[2px]" /> */}
                                                                    <span className="text-gray-500">
                                                                        from {fromName}
                                                                    </span>
                                                                </span>
                                                            </div>
                                                        </div>
                                                        {(isDriversMessage === true) &&
                                                            <NavUserAvatar user={driverUser} link={"#"} imgSizeClass={`w-[30px] h-[30px] max-w-[30px] max-h-[30px]'} block`} />
                                                        }
                                                    </div>)
                                            }
                                        })}
                                    </div>
                                    {/* The footer section */}
                                    <div className=" w-full bg-[#f0f2f5] rounded-br-md flex flex-col items-start justify-start p-1 md:p-0">
                                        {sendMessageErrors.length > 0 &&
                                            <div className="app-error text-red-400 text-xs pt-2 pb-1 px-2 ">
                                                {sendMessageErrors[0]}
                                            </div>
                                        }
                                        <div className="flex-grow py-2 gap-x-1 flex flex-row items-start justify-center w-full relative ">

                                            <div className="flex flex-col items-center space-y-1">
                                                <button
                                                    type="button"
                                                    onClick={toggleEmojiPicker}
                                                    className="cursor-pointer p-1 text-md bg-gray-200 rounded-md text-purple-900 hover:text-purple-700 flex flex-row justify-center text-center"
                                                >
                                                    <EmojiIcon />
                                                </button>

                                                <label
                                                    htmlFor="image-upload"
                                                    className="cursor-pointer p-1 text-md bg-gray-200 rounded-md text-purple-900 hover:text-purple-700 flex flex-row justify-center text-center"
                                                >
                                                    <PictureIcon />
                                                    <input
                                                        type="file"
                                                        id="image-upload"
                                                        accept="image/*"
                                                        onChange={handleImageChange}
                                                        className="hidden"
                                                    />
                                                </label>
                                            </div>

                                            {showEmojiPicker && (
                                                <div
                                                    className="absolute bottom-14 left-0 md:left-5  bg-white border rounded-md shadow-lg"
                                                    style={{ zIndex: 1000 }}
                                                >
                                                    <EmojiPicker
                                                        onEmojiClick={handleEmojiClick}
                                                        skinTonesDisabled={true}
                                                        searchDisabled={true}
                                                    />
                                                </div>
                                            )}

                                            {(selectedImage && selectedImagePreview) && (
                                                <div
                                                    className="absolute bottom-8 left-5 bg-white border rounded-md shadow-lg p-4 flex flex-col items-center gap-y-2 min-w-[300px]"
                                                    style={{ zIndex: 1000 }}
                                                >
                                                    <div className="flex flex-row justify-between items-center w-full">
                                                        <p className="text-sm text-gray-600 text-bold">Selected Image Preview</p>

                                                        <button
                                                            onClick={handleCancelImage} // Function to handle closing
                                                            className="text-gray-500 hover:text-gray-400 transition"
                                                            aria-label="Close Preview"
                                                        >
                                                            ✖
                                                        </button>
                                                    </div>
                                                    <img
                                                        src={selectedImagePreview}
                                                        alt="Selected"
                                                        className="w-full h-auto rounded-md"
                                                    />


                                                    <input
                                                        type="text"
                                                        value={caption}
                                                        disabled={isSendMessageLoading || isFileUpLoading}
                                                        onChange={(e) => setCaption(e.target.value)}
                                                        placeholder="Add a caption..."
                                                        className="block w-full rounded-md border-0 px-2.5 py-1 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                                                    />

                                                    <div className="flex flex-row justify-between items-center w-full">
                                                        <button role="button"
                                                            type="button"
                                                            disabled={isSendMessageLoading || isFileUpLoading}
                                                            onClick={handleCancelImage}
                                                            className="flex gap-x-2 relative justify-center items-center flex-col rounded-md bg-gray-500 hover:bg-gray-400 p-1.5 text-sm font-semibold leading-6 text-white shadow-sm  disabled:cursor-not-allowed "
                                                        >
                                                            Cancel
                                                        </button>


                                                        <button role="button"
                                                            type="button"
                                                            disabled={isSendMessageLoading || isFileUpLoading}
                                                            onClick={handleAcceptImage}
                                                            className="flex gap-x-2 relative justify-center items-center flex-col  rounded-md bg-purple-900  p-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-800  disabled:cursor-not-allowed "
                                                        >
                                                            {isFileUpLoading &&
                                                                <div className="text-xs absolute top-[-3px]">
                                                                    <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />
                                                                </div>
                                                            }

                                                            Upload Image
                                                        </button>
                                                    </div>
                                                </div>
                                            )}

                                            <textarea
                                                type="text"
                                                name="message-input"
                                                id="message-input"
                                                autoComplete="message-input"
                                                value={otherUserId === "" ? "" : messageInputText[otherUserId]}
                                                disabled={isSendMessageLoading || isFileUpLoading}
                                                placeholder="Type a message 😊"
                                                onKeyDown={handleMessageInputKeyDown}
                                                onChange={handleMessageInputChange}
                                                ref={textAreaRef}
                                                style={{ maxHeight: maxHeight + "px", roundedTopRight: "0px", backgroundColor: "white!important" }}
                                                className="bg-white mb-0 block w-full  min-h-[60px] h-[60px] rounded-md  border-0 py-1  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 xdisabled:bg-gray-100 disabled:cursor-progress"
                                            ></textarea>

                                            <button role="button"
                                                type="submit"
                                                disabled={isSendMessageLoading || isFileUpLoading}
                                                onClick={sendMessage}
                                                className="flex gap-x-2 relative justify-center items-center flex-col w-[100px] rounded-md bg-purple-900 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-purple-800  disabled:cursor-not-allowed "
                                            >
                                                {isSendMessageLoading &&
                                                    <div className="text-xs absolute top-[-3px]">
                                                        <Loader type="ball-beat" style={{ transform: 'scale(0.5)' }} />
                                                    </div>
                                                }
                                                <div className="flex flex-row items-center gap-x-2">
                                                    <SendIcon />  Send
                                                </div>
                                            </button>


                                        </div>
                                    </div>
                                    {isMedialViewDialogOpen && (
                                        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
                                            <div className="relative bg-white p-4 rounded-md max-w-[90%] max-h-[90%]">
                                                <button
                                                    className="absolute top-2 right-2 text-white bg-gray-500 rounded-md p-1 w-10"
                                                    onClick={closeMediaViewDialog}
                                                >
                                                    ✖
                                                </button>
                                                <img
                                                    src={medialViewDialogMedia}
                                                    alt="Expanded Media"
                                                    className="max-w-full max-h-full rounded-md"
                                                />
                                            </div>
                                        </div>
                                    )}     
                                </div>
                            </div>
                        )
                    })()}


                </div>
            </div>
        </MainLayout>
    );
};



export default Chat;
